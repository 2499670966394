import React from "react";
import { Link } from "gatsby";

import "./style.sass";

const Button = ({
  linkRef,
  clickFn,
  children,
  externalRef,
  ariaLabel = "",
  icon = false,
  invertColor = false,
  classname = ""
}) => {
  const buttonClass = `button ${invertColor ? " invert-color" : ""} ${
    !linkRef && !clickFn && !externalRef ? " no-op" : ""
  }${classname}`;

  if (linkRef) {
    return (
      <Link className={buttonClass} to={linkRef} aria-label={ariaLabel}>
        <button aria-label={ariaLabel}>{children}</button>
      </Link>
    );
  }

  if (externalRef) {
    return (
      <a
        className={buttonClass}
        target="_blank"
        rel="noopener noreferrer"
        href={externalRef}
        aria-label={ariaLabel}
      >
        <button aria-label={ariaLabel}>{children}</button>
      </a>
    );
  }

  if (clickFn) {
    return (
      <div className={buttonClass}>
        <button aria-label={ariaLabel} onClick={clickFn}>
          {children}
        </button>
      </div>
    );
  }

  return (
    <div className={buttonClass}>
      <button aria-label={ariaLabel}>{children}</button>
    </div>
  );
};

export default Button;
