import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Button from "../components/Button";

import "./level-post.sass";

import fbIcon from "../img/facebook-icon.svg";
import twIcon from "../img/twitter-icon.svg";
import inIcon from "../img/linkedin-icon.svg";
import leftArrow from "../img/left-arrow.svg";
import soldTag from "../img/sold-tag.svg";
import sqmIcon from "../img/sqm-icon.svg";
import bathIcon from "../img/bath-icon.svg";

const campaignId = "59796";
const CHUFFED_BASE_PATH = `https://chuffed.org/pay/campaign/${campaignId}/?perk_id=`;

export const LevelPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  image,
  helmet,
  owner,
  currentPageUrl,
  chuffedId,
  pageContext: { level, sqm, bathrooms, wildcardcount, wildcardicon, price }
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="level-post">
      {helmet || ""}
      <Link to="/levels" className="back-button">
        <div className="arrow">
          <img src={leftArrow} alt="Share on Instagram" />
        </div>
        <div>Back</div>
      </Link>
      <div className="details">
        <div className="top">
          <div className="stats">
            <div className="lvl">
              <div>Lvl&nbsp;{level}</div>
            </div>
            <div className="sqm">
              <div className="svg-wrap">
                <img src={sqmIcon} alt="sqm icon" />
              </div>

              <div>{sqm}sqm</div>
            </div>
            <div className="bedetc">
              <div>{bathrooms}</div>
              <div className="svg-wrap">
                <img src={bathIcon} alt="sqm icon" />
              </div>

              <div>{wildcardcount}</div>
              <div className="svg-wrap">
                <img
                  src={`/img/${wildcardicon.relativePath}`}
                  alt="wildcard icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inner">
          <div className="price-cta-wrap">
            <div className="left">
              <h2>
                {new Intl.NumberFormat("en-AU", {
                  style: "currency",
                  currency: "AUD",
                  minimumFractionDigits: 0
                }).format(price)}
              </h2>
            </div>

            <div className="right">
              {owner && owner !== "0" ? (
                <div className="owner">
                  <h5>Owned by:</h5>
                  <p>{owner}</p>
                </div>
              ) : (
                <Button externalRef={`${CHUFFED_BASE_PATH}${chuffedId}`}>
                  BUY NOW
                </Button>
              )}
            </div>
          </div>

          <h1 className="">{title}</h1>

          <PostContent content={content} />
        </div>
        <section className="bottom">
          <div className="share">
            <div className="left">SHARE</div>
            <div className="right">
              <div className="share-buttons">
                <a
                  className="navbar-item"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}&quote=${description}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <img src={fbIcon} alt="Share on Facebook" />
                  </span>
                </a>

                <a
                  className="navbar-item"
                  href={`https://twitter.com/intent/tweet?url=${currentPageUrl}&text=${description}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <img src={twIcon} alt="Share on Twitter" />
                  </span>
                </a>

                <a
                  className="navbar-item"
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPageUrl}&title=${title}&summary=${description}&Source=100StoryBuilding`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <img src={inIcon} alt="Share on LinkedIn" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="img-block">
        <Img fluid={image} />
        {owner && owner !== "0" ? (
          <div className="sold-tag">
            <img src={soldTag} alt="SOLD" />
          </div>
        ) : null}
      </div>
    </section>
  );
};

LevelPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const LevelPost = ({ data, pageContext, location: { href } }) => {
  const { markdownRemark: post } = data;
  const {
    frontmatter: { title, description }
  } = post;

  return (
    <Layout currentPageUrl={href} showNavbar={false}>
      <LevelPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        chuffedId={post.frontmatter.chuffedId}
        description={description}
        helmet={
          <Helmet>
            <title>{`${title} | 100 Story Building`}</title>
            <meta name="description" content={`${description}`} />
            {/* <!-- Twitter Card data --> */}
            <meta
              name="twitter:title"
              content={`${title} | 100 Story Building`}
            />
            <meta name="twitter:description" content={description} />
            {/* <-- Twitter Summary card images must be at least 120x120px --> */}
            <meta
              name="twitter:image"
              content={
                post.frontmatter.thumbnailimage.childImageSharp.fixed.src
              }
            />

            {/* <!-- Open Graph data --> */}
            <meta
              property="og:title"
              content={`${title} | 100 Story Building`}
            />
            <meta property="og:url" content={href} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="article" />
            <meta
              property="og:image"
              content={
                post.frontmatter.thumbnailimage.childImageSharp.fixed.src
              }
            />
            <meta property="og:image:alt" content={title} />
          </Helmet>
        }
        currentPageUrl={href}
        owner={post.frontmatter.owner}
        title={post.frontmatter.title}
        pageContext={pageContext}
        image={post.frontmatter.featuredimage.childImageSharp.fluid}
      />
    </Layout>
  );
};

LevelPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default LevelPost;

export const pageQuery = graphql`
  query LevelPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        chuffedId
        owner
        thumbnailimage {
          childImageSharp {
            fixed(width: 240, height: 240, quality: 100) {
              src
            }
          }
        }
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
